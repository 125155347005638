/**
 * Return a random int number
 * Set a minimun and a maximum range
 * @param min default 0
 * @param max defautl 100
 */
const random = (min = 0, max = 100) => Math.floor(Math.random() * (max - min + 1)) + min;
class increaseDecrease {
    constructor(range, step) {
        this.forward = true;
        this.range = range;
        this.step = step | 1;
    }
    number() {
        this.num = isNaN(this.num)
            ? 0
            : this.forward && this.num < this.range
                ? this.num + this.step
                : this.num > 0
                    ? this.num - this.step
                    : this.num + this.step;
        this.forward =
            this.forward && this.num < this.range
                ? true
                : this.num > 0
                    ? false
                    : true;
        return this.num;
    }
}
const increaseDecreaseArray = (range, times = 1, step = 1) => {
    const num = Math.round(range / step);
    const arr = [...Array(num).keys()].map((x) => step * x);
    const reverse = [...arr].reverse();
    const middle = arr[arr.length - 1] + step;
    const incDec = [...arr, middle, ...reverse];
    const final = [...incDec];
    if (times > 1) {
        const second = [...incDec];
        second.shift();
        for (let index = 1; index < times; index++) {
            final.push(...second);
        }
    }
    return final;
};
// generators-------
function* increaseDecreaseGeneratorArray(range, times = 1, step = 1) {
    const num = Math.round(range / step);
    const arr = [...Array(num).keys()].map((x) => step * x);
    const reverse = [...arr].reverse();
    const middle = arr[arr.length - 1] + step;
    const incDec = [...arr, middle, ...reverse];
    const final = [...incDec];
    if (times > 1) {
        const second = [...incDec];
        second.shift();
        for (let index = 1; index < times; index++) {
            final.push(...second);
        }
    }
    yield* final;
}
function* increaseDecreaseGenerator(range, step = 1) {
    let num;
    let forward = true;
    while (true) {
        num = isNaN(num)
            ? 0
            : forward && num < range
                ? num + step
                : num > 0
                    ? num - step
                    : num + step;
        forward = forward && num < range ? true : num > 0 ? false : true;
        yield num;
    }
}
function* increaseGenerator(start = 0, step = 1) {
    let num = start;
    while (true) {
        yield num;
        num = num + step;
    }
}
function* decreaseGenerator(start = 0, step = 1) {
    let num = start;
    while (true) {
        yield num;
        num = num - step;
    }
}
const setStep = (num) => {
    return Number(num
        .toString()
        .split("")
        .map((x, index, arr) => {
        return isNaN(x) ? x : index == arr.length - 1 ? 1 : 0;
    })
        .join(""));
};
const setPositional = (num) => {
    return Number(num
        .toString()
        .split("")
        .map((x, index, arr) => (isNaN(x) ? x : index == 0 ? 1 : 0))
        .join(""));
};
const setRange = (num) => [...Array(num).keys()];
const setAngle = (total, index) => ((Math.PI * 2) / total) * index;
const setRotation = (angle, baseAngle, radius) => Math.cos(angle + baseAngle) * radius;
const setRotationZ = (angle, baseAngle, radius, centerZ) => centerZ + Math.sin(angle + baseAngle) * radius;
const sortAxis = (a, b, axis) => b[axis] - a[axis];
const sortAxisX = (a, b) => sortAxis(a, b, "x");
const sortAxisY = (a, b) => sortAxis(a, b, "y");
const sortAxisZ = (a, b) => sortAxis(a, b, "z");
const toZero = (num, step) => {
    const bar = num > 0
        ? num > step
            ? num - step
            : 0
        : num < 0
            ? num < step
                ? num + step
                : 0
            : 0;
    return bar;
};
const rest = (value, total) => Math.abs(value - total);
const percentage = (value, total, reverseValue = false) => {
    const num = reverseValue ? rest(value, total) : value;
    return Math.trunc((num / total) * 100);
};
const generateRandomKeys = (quantity, total) => {
    const keys = [];
    const frame = 100 / quantity;
    const value = total / quantity;
    for (let index = 0; index < quantity; index++) {
        keys.push({
            frame: index == 0 ? 0 : frame * index + frame,
            value: index == quantity - 1 ? keys[0].value : value * index + value,
        });
    }
    return keys;
};
export { increaseDecrease, increaseDecreaseArray, increaseDecreaseGeneratorArray, increaseDecreaseGenerator, increaseGenerator, decreaseGenerator, setStep, setPositional, setRange, setAngle, setRotation, setRotationZ, sortAxis, sortAxisX, sortAxisY, sortAxisZ, random, toZero, percentage, generateRandomKeys, };
