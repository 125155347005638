<script lang="ts">
    import { randomQuote } from "./modules/quotes";

    const quote = randomQuote();
</script>

<svelte:head>
    <title>Treedbox - Web solutions</title>
</svelte:head>

<main class="fadeout">
    <div class="treedbox" title="Treedbox" />
    <footer>
        <article>
            <p><q>{quote.text}</q></p>
            <p><small>- {quote.author} -</small></p>
        </article>
        <small>
            <a href="mailto:treedbox@gmail.com">treedbox@gmail.com</a>
        </small>
    </footer>
</main>

<style>
    :global(body) {
        padding: 0;
        background-color: #ffdd00;
    }
    main {
        padding: 0;
        text-align: center;
        animation: fadeout forwards 10s;
        overflow: hidden;
        display: grid;
        grid-template-rows: 1fr auto;
        height: 100%;
    }
    .treedbox {
        background-image: url(./assets/svg/treedbox.svg);
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center 5rem;
        width: 100%;
        height: 100%;
    }
    footer {
        text-align: center;
        color: #fd0;
        font-size: 1.5rem;
        position: absolute;
        bottom: 0;
        background-color: #000;
        width: 100%;
        padding-bottom: 1rem;
    }
    article {
        max-width: 90%;
        margin: 0 auto;
    }
    footer small {
        font-size: 1rem;
    }
    footer a {
        color: #fd0;
    }
    @keyframes fadeout {
        0% {
            background-color: #fff;
        }
        100% {
            background-color: transparent;
        }
    }

    @media (min-width: 640px) {
        main {
            max-width: none;
        }
    }
</style>
